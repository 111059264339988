import { Dispatch, FC, SetStateAction, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';

import { useAppDispatch, useAppSelector } from '../../../../app/hooks';
import { Button, CardItemList, Loading, PlayerDetails } from '../../../../components';
import {
  filteredMainFilterDataSelector,
  selectComparePlayers,
  selectNavigation,
  selectPlayers,
  setComparePlayerItems,
} from '../../../../features';
import { useExportAsImage, useLocationPaths } from '../../../../hooks';
import { ITranslationKeys } from '../../../../i18n/types';
import { DownloadIcon } from '../../../../icons';
import { useLazyGetPlayerCardInfoQuery } from '../../../../services/api';
import {
  INavigationContentKeys,
  INavigationPageKeys,
  IReturnTypeGetPlayerCardInfoAction,
  ISelectOption,
} from '../../../../types';
import { createClassNames, transformObjectKeysToKebabCase } from '../../../../utils';
import { useGetPlayerCards } from '../shared';
import './PlayerCardContentComponent.styles.scss';

interface IPlayerCardContentComponentProps {
  submitValues: {
    selectedTeam: ISelectOption | null | undefined;
    selectedPlayer: ISelectOption | null | undefined;
  };
  loadedPlayerData: IReturnTypeGetPlayerCardInfoAction | undefined;
  setLoadedPlayerData: Dispatch<SetStateAction<IReturnTypeGetPlayerCardInfoAction | undefined>>;
}

const classNames = createClassNames('player-card-content-component');

export const PlayerCardContentComponent: FC<IPlayerCardContentComponentProps> = ({
  submitValues,
  loadedPlayerData,
  setLoadedPlayerData,
}) => {
  const { byId } = useAppSelector(selectPlayers);
  const { comparePlayerItems } = useAppSelector(selectComparePlayers);
  const { filteredParts } = useAppSelector(filteredMainFilterDataSelector);
  const { triggerContentReloadValue } = useAppSelector(selectNavigation);
  const dispatch = useAppDispatch();

  const [trigger, { error, isFetching }] = useLazyGetPlayerCardInfoQuery();

  const { t } = useTranslation();
  const { navigate, search } = useLocationPaths();
  const { getPlayerCards } = useGetPlayerCards();

  const onSubmitPlayer = () => {
    if (!submitValues.selectedTeam || !submitValues.selectedPlayer) return;

    trigger(
      {
        competitionUuid: filteredParts[0].id,
        teamUuid: submitValues.selectedTeam.value,
        playerUuid: submitValues.selectedPlayer.value,
      },
      true,
    )
      .unwrap()
      .then(data => setLoadedPlayerData(data))
      .catch(error => {
        console.error('[PlayerCardContentComponent]: Player info load error:', error);
      });
  };

  useEffect(() => {
    if (filteredParts.length > 1) {
      toast.warn(t(ITranslationKeys.pleaseSelectOnlyOneSeason), {
        toastId: 'pleaseSelectOnlyOneSeason',
      });

      return;
    }

    if (triggerContentReloadValue !== 0) {
      onSubmitPlayer();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [triggerContentReloadValue]);

  const exportRef = useRef<HTMLDivElement>(null);
  const { isExporting, exportImage } = useExportAsImage(exportRef, `hokejLogic-player-card`, 'png');

  if (isFetching) {
    return <Loading />;
  }

  if (error) {
    return <div className='content-info-box'>{t(ITranslationKeys.defaultErrorMessage)}</div>;
  }

  if (!loadedPlayerData) {
    return <div className='content-info-box'>{t(ITranslationKeys.noDataForSelected)}</div>;
  }

  const player = byId[loadedPlayerData.playerId];

  const handleCompareWithOtherPlayer = () => {
    navigate(INavigationPageKeys.players, INavigationContentKeys.comparePlayers, search);
    dispatch(
      setComparePlayerItems({
        comparePlayerItems: {
          ...comparePlayerItems[0],
          selectedPlayerCompare: {
            value: loadedPlayerData.playerId,
            label: `${player.name} ${player.surname}`,
            additionalValue: loadedPlayerData.playerTeamId,
          },
          actualCompareLoadedPlayer: {
            id: loadedPlayerData.playerId,
            teamId: loadedPlayerData.playerTeamId,
          },
        },
        itemKey: '0',
      }),
    );
  };

  return (
    <div
      ref={exportRef}
      className={classNames({
        ...transformObjectKeysToKebabCase({
          isLoading: isFetching,
        }),
      })}
      data-testid='players-page__player-card'
    >
      <PlayerDetails
        player={player}
        playerInfo={loadedPlayerData.playerInfo}
        actions={
          <>
            <Button
              label={ITranslationKeys.compareWithOtherPlayer}
              onClick={handleCompareWithOtherPlayer}
              disabled={isExporting}
            />
            <Button
              label={ITranslationKeys.downloadPlayerCard}
              iconComponent={<DownloadIcon />}
              onClick={exportImage}
              disabled={isExporting}
            />
          </>
        }
      />
      <CardItemList isExporting={isExporting} mode='wrap'>
        {getPlayerCards(loadedPlayerData.playerInfo, loadedPlayerData.playerId)}
      </CardItemList>
    </div>
  );
};
