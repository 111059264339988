import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { useAppSelector } from '../../../../app/hooks';
import { filteredMainFilterDataSelector, selectMainFilter } from '../../../../features';
import { ITranslationKeys } from '../../../../i18n/types';
import { IReturnTypeGetPlayerCardInfoAction } from '../../../../types';
import { PlayerCardContentComponent } from './PlayerCardContentComponent';

export const PlayerCardContent = () => {
  const { selectedTeam, selectedPlayer } = useAppSelector(selectMainFilter);
  const { filteredParts } = useAppSelector(filteredMainFilterDataSelector);

  const [loadedPlayerData, setLoadedPlayerData] = useState<
    IReturnTypeGetPlayerCardInfoAction | undefined
  >(undefined);

  const { t } = useTranslation();

  if (filteredParts.length === 0 && !loadedPlayerData) {
    return (
      <div className='content-info-box'>{t(ITranslationKeys.pleaseSelectOneSeasonAndOnePart)}</div>
    );
  }

  if (filteredParts.length > 1 && !loadedPlayerData) {
    return <div className='content-info-box'>{t(ITranslationKeys.pleaseSelectOnlyOneSeason)}</div>;
  }

  if (!(selectedTeam && selectedPlayer) && !loadedPlayerData) {
    return <div className='content-info-box'>{t(ITranslationKeys.pleaseSelectTeamAndPlayer)}</div>;
  }

  return (
    <PlayerCardContentComponent
      submitValues={{ selectedTeam, selectedPlayer }}
      {...{ loadedPlayerData, setLoadedPlayerData }}
    />
  );
};
