import { FC, useEffect, useState } from 'react';
import { Control, Controller } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';

import { useAppDispatch, useAppSelector } from '../../../../app/hooks';
import {
  BasicPlayerInfo,
  Button,
  Caption,
  CardItemList,
  IComparePlayerForm,
  Loading,
  SearchInput,
} from '../../../../components';
import {
  filteredMainFilterDataSelector,
  filteredPlayersForCompareOptionsSelector,
  selectComparePlayers,
  selectPlayers,
  setComparePlayerItems,
} from '../../../../features';
import { useLocationPaths } from '../../../../hooks';
import { ITranslationKeys } from '../../../../i18n/types';
import { useLazyGetPlayerCardInfoQuery } from '../../../../services/api';
import {
  IMultiParamsVoid,
  IReturnTypeGetPlayerCardInfoAction,
  ISelectOption,
} from '../../../../types';
import { createClassNames } from '../../../../utils';
import { useGetPlayerCards } from '../shared';
import './ComparePlayersContentComponent.styles.scss';

interface IComparePlayersContentComponentProps {
  selectedPlayerCompare: ISelectOption | undefined;
  itemIndex: string;
  control: Control<IComparePlayerForm, any>;
}

const classNames = createClassNames('compare-players-content-component');

export const ComparePlayersContentComponent: FC<IComparePlayersContentComponentProps> = ({
  selectedPlayerCompare,
  itemIndex,
  control,
}) => {
  const { filteredParts } = useAppSelector(filteredMainFilterDataSelector);
  const { comparePlayerItems } = useAppSelector(selectComparePlayers);
  const { byId } = useAppSelector(selectPlayers);
  const filteredPlayersForCompareOptions = useAppSelector(filteredPlayersForCompareOptionsSelector);
  const dispatch = useAppDispatch();

  const [loadedPlayerData, setLoadedPlayerData] = useState<
    IReturnTypeGetPlayerCardInfoAction | undefined
  >(undefined);

  const [trigger, { error, isFetching }] = useLazyGetPlayerCardInfoQuery();

  const { t } = useTranslation();
  const { activeTab } = useLocationPaths();
  const { getPlayerCards } = useGetPlayerCards();

  const handleChangePlayer = (
    value: ISelectOption,
    itemKey: string,
    onChange: IMultiParamsVoid,
  ) => {
    onChange(value);
    dispatch(
      setComparePlayerItems({
        comparePlayerItems: {
          ...comparePlayerItems[itemKey],
          selectedPlayerCompare: value,
        },
        itemKey,
      }),
    );
  };

  const onSubmitPlayer = () => {
    if (filteredParts.length > 1) {
      toast.warn(t(ITranslationKeys.pleaseSelectOnlyOneSeason), {
        toastId: 'pleaseSelectOnlyOneSeason',
      });

      return;
    }

    trigger(
      {
        competitionUuid: filteredParts[0]?.id,
        teamUuid: selectedPlayerCompare?.additionalValue || '',
        playerUuid: selectedPlayerCompare?.value || '',
      },
      true,
    )
      .unwrap()
      .then(data => setLoadedPlayerData(data))
      .catch(error => {
        console.error('[ComparePlayersContentComponent]: Player info load error:', error);
      });
  };

  useEffect(() => {
    if (selectedPlayerCompare) {
      const playerExist = filteredPlayersForCompareOptions.some(
        option => option.value === selectedPlayerCompare?.value,
      );

      if (!playerExist) {
        dispatch(
          setComparePlayerItems({
            comparePlayerItems: {
              ...comparePlayerItems[itemIndex],
              selectedPlayerCompare: undefined,
            },
            itemKey: itemIndex,
          }),
        );
        return;
      }

      onSubmitPlayer();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeTab]);

  const render = () => {
    if (filteredParts.length === 0 && !loadedPlayerData) {
      return (
        <div className='content-info-box'>
          {t(ITranslationKeys.pleaseSelectOneSeasonAndOnePart)}
        </div>
      );
    }

    if (filteredParts.length > 1 && !loadedPlayerData) {
      return (
        <div className='content-info-box'>{t(ITranslationKeys.pleaseSelectOnlyOneSeason)}</div>
      );
    }

    if (isFetching) {
      return <Loading size='medium' disableHorizontalMargin />;
    }

    if (error) {
      return <div className='content-info-box'>{t(ITranslationKeys.defaultErrorMessage)}</div>;
    }

    if (!loadedPlayerData) return null;

    const player = byId[loadedPlayerData.playerId];

    return (
      <>
        <BasicPlayerInfo
          name={`${player.name} ${player.surname}`}
          toi={loadedPlayerData.playerInfo.toi ?? 0}
          gp={loadedPlayerData.playerInfo.gp ?? 0}
        />
        <CardItemList isAtCompare>
          {getPlayerCards(loadedPlayerData.playerInfo, loadedPlayerData.playerId)}
        </CardItemList>
      </>
    );
  };

  return (
    <div className={classNames()}>
      <div className={classNames('inputs')} data-testid='players-page__compare-player__inputs'>
        <Caption label={ITranslationKeys.selectPlayerToCompare} />
        <Controller
          name={`players.${itemIndex}`}
          control={control}
          render={({ field: { value, onChange } }) => (
            <div className={classNames('inputs__control-box')}>
              <SearchInput
                onChange={value => handleChangePlayer(value, itemIndex, onChange)}
                selected={value ?? undefined}
                options={filteredPlayersForCompareOptions}
                placeholder={`${t(ITranslationKeys.defaultSelectPlaceholder)}`}
                variant='filter'
              />
              <Button label={ITranslationKeys.select} disabled={!value} onClick={onSubmitPlayer} />
            </div>
          )}
        />
      </div>
      <div className={classNames('cards')} data-testid='players-page__compare-players__card'>
        {render()}
      </div>
    </div>
  );
};
