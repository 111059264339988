import { PayloadAction, createSlice } from '@reduxjs/toolkit';

import {
  ICompareGoalkeeperItemPayload,
  IComparePlayerItemPayload,
  IComparePlayerRecord,
  IComparePlayerState,
} from '../../types';

const initialComparePlayerAndGoalkeeperItems: IComparePlayerRecord = {
  '0': { id: '0' },
  '1': { id: '1' },
  '2': { id: '2' },
  '3': { id: '3' },
};

const initialState: IComparePlayerState = {
  comparePlayerItems: initialComparePlayerAndGoalkeeperItems,
  compareGoalkeeperItems: initialComparePlayerAndGoalkeeperItems,
};

export const comparePlayersSlice = createSlice({
  name: 'comparePlayers',
  initialState,
  reducers: {
    setComparePlayerItems: (state, action: PayloadAction<IComparePlayerItemPayload>) => {
      state.comparePlayerItems[action.payload.itemKey] = action.payload.comparePlayerItems;
    },
    setCompareGoalkeeperItems: (state, action: PayloadAction<ICompareGoalkeeperItemPayload>) => {
      state.compareGoalkeeperItems[action.payload.itemKey] = action.payload.compareGoalkeeperItems;
    },
    resetComparePlayers: () => initialState,
  },
});

export const { setComparePlayerItems, setCompareGoalkeeperItems } = comparePlayersSlice.actions;

export const comparePlayersReducer = comparePlayersSlice.reducer;
