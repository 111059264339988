import {
  IGoalkeeperCardInfoDTO,
  IPlayerWithStatsDTO,
  IPlayerWithTeamIdAndStats,
  IReturnTypeGetGoalkeeperCardInfoAction,
  ITeamWithPlayersWithStatsDTO,
} from '../../../types';
import { api } from '../api';
import {
  getGoalkeeperStatsRequestUrl,
  parseGoalkeeperCardInfoData,
  parseGoalkeeperStatsData,
} from './goalkeepers.utils';
import { IGetGoalkeeperCardInfoParams, IGetGoalkeeperStatsParams } from './types';

export const goalkeepersApi = api.injectEndpoints({
  endpoints: builder => ({
    getGoalkeeperCardInfo: builder.query<
      IReturnTypeGetGoalkeeperCardInfoAction,
      IGetGoalkeeperCardInfoParams
    >({
      query: ({ competitionUuid, teamUuid, playerUuid }) => ({
        url: `/goalkeeper/${competitionUuid}/${teamUuid}/${playerUuid}`,
        method: 'get',
      }),
      transformResponse: (data: IGoalkeeperCardInfoDTO, _, arg) =>
        parseGoalkeeperCardInfoData(data, arg.teamUuid),
    }),
    getGoalkeepersStats: builder.query<IPlayerWithTeamIdAndStats[], IGetGoalkeeperStatsParams>({
      query: ({ competitionsUuids, teamUuid, body, isPercentile }) => {
        const requestPath = getGoalkeeperStatsRequestUrl({
          competitionsUuids,
          teamUuid,
          isPercentile,
        });
        return {
          url: requestPath,
          method: 'post',
          body,
        };
      },
      transformResponse: (data: ITeamWithPlayersWithStatsDTO[] | IPlayerWithStatsDTO[], _, arg) =>
        parseGoalkeeperStatsData(data, arg.teamUuid),
    }),
  }),
});

export const {
  useGetGoalkeeperCardInfoQuery,
  useLazyGetGoalkeeperCardInfoQuery,
  useGetGoalkeepersStatsQuery,
  useLazyGetGoalkeepersStatsQuery,
} = goalkeepersApi;
