import { useEffect } from 'react';
import { useForm, useFormContext } from 'react-hook-form';

import { useAppSelector } from '../../../../app/hooks';
import { ICompareGoalkeeperForm } from '../../../../components';
import { selectComparePlayers } from '../../../../features';
import { IMainFilterForm, ISelectOption } from '../../../../types';
import { createClassNames } from '../../../../utils';
import './CompareGoalkeepersContent.styles.scss';
import { CompareGoalkeepersContentComponent } from './CompareGoalkeepersContentComponent';

const classNames = createClassNames('compare-goalkeepers-content');

export const CompareGoalkeepersContent = () => {
  const { compareGoalkeeperItems } = useAppSelector(selectComparePlayers);

  const mainFilterMethods = useFormContext<IMainFilterForm>();

  const { control, reset } = useForm<ICompareGoalkeeperForm>({
    defaultValues: {
      goalkeepers: {
        '0': null,
        '1': null,
        '2': null,
        '3': null,
      },
    },
  });

  useEffect(() => {
    const definedSelectedGoalkeeperOptions = Object.values(compareGoalkeeperItems).reduce<
      Record<string, ISelectOption | null>
    >((acc, item) => {
      if (item.selectedGoalkeeperCompare) {
        acc[item.id] = item.selectedGoalkeeperCompare;
      } else {
        acc[item.id] = null;
      }

      return acc;
    }, {});

    reset({ goalkeepers: definedSelectedGoalkeeperOptions });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [compareGoalkeeperItems]);

  return (
    <div className={classNames()} data-testid='players-page__compare-player'>
      <div className={classNames('background')}></div>
      <div className={classNames('content')}>
        {Object.values(compareGoalkeeperItems).map((item, index) => {
          const { selectedGoalkeeperCompare } = item;

          return (
            <CompareGoalkeepersContentComponent
              key={index}
              selectedPlayerCompare={selectedGoalkeeperCompare || undefined}
              itemIndex={index.toString()}
              control={control}
              mainFilterMethods={mainFilterMethods}
            />
          );
        })}
      </div>
    </div>
  );
};
