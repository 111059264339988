import {
  IPlayerCardInfo,
  IPlayerCardInfoDTO,
  IReturnTypeGetPlayerCardInfoAction,
} from '../../../types';

export const parsePlayerCardInfoData = (
  data: IPlayerCardInfoDTO,
  competitionUuid: string,
  teamUuid: string,
): IReturnTypeGetPlayerCardInfoAction => {
  const { player, ...playerInfo } = data;

  const newPlayerInfo: IPlayerCardInfo = {
    competitionId: competitionUuid,
    teamId: teamUuid,
    toi: playerInfo.toi,
    gp: playerInfo.gp,
    group1: {
      percentile: playerInfo.group1?.percentile,
      stats: playerInfo.group1?.metrics,
      percentiles: playerInfo.group1?.percentiles,
    },
    group2: {
      percentile: playerInfo.group2?.percentile,
      stats: playerInfo.group2?.metrics,
      percentiles: playerInfo.group2?.percentiles,
    },
    group3: {
      percentile: playerInfo.group3?.percentile,
      stats: playerInfo.group3?.metrics,
      percentiles: playerInfo.group3?.percentiles,
    },
    group4: {
      percentile: playerInfo.group4?.percentile,
      stats: playerInfo.group4?.metrics,
      percentiles: playerInfo.group4?.percentiles,
    },
    group5: {
      percentile: playerInfo.group5?.percentile,
      stats: playerInfo.group5?.metrics,
      percentiles: playerInfo.group5?.percentiles,
    },
  };

  return {
    playerId: player.uuid,
    playerTeamId: teamUuid,
    playerInfo: newPlayerInfo,
  };
};
