import {
  IGoalkeeperCardInfoDTO,
  IGoalkeeperNetZoneCardPlayerStats,
  IPlayerWithStatsDTO,
  IPlayerWithTeamIdAndStats,
  IReturnTypeGetGoalkeeperCardInfoAction,
  ITeamWithPlayersWithStatsDTO,
} from '../../../types';
import { buildPath, parseGoalkeepersStatsData, urlBooleanParamResolver } from '../../../utils';
import { IGetGoalkeeperStatsParams } from './types';

export const parseGoalkeeperCardInfoData = (
  data: IGoalkeeperCardInfoDTO,
  teamUuid: string,
): IReturnTypeGetGoalkeeperCardInfoAction => {
  const { player, ...otherInfo } = data;
  return {
    goalkeeperId: player.uuid,
    goalkeeperTeamId: teamUuid,
    goalkeeperInfo: {
      teamId: teamUuid,
      toi: otherInfo.toi,
      gp: otherInfo.gp,
      stats: otherInfo.metrics
        ? {
            percentile: otherInfo.metrics.percentile,
            summaryRank: otherInfo.metrics.summaryRank,
            stats: otherInfo.metrics.metrics,
            percentiles: otherInfo.metrics.percentiles,
            ranks: otherInfo.metrics.ranks,
          }
        : undefined,
      lastPlayedMatchesStats: otherInfo.lastPlayedMatchesMetrics
        ? {
            percentile: otherInfo.lastPlayedMatchesMetrics.percentile,
            summaryRank: otherInfo.lastPlayedMatchesMetrics.summaryRank,
            stats: otherInfo.lastPlayedMatchesMetrics.metrics,
            percentiles: otherInfo.lastPlayedMatchesMetrics.percentiles,
            ranks: otherInfo.lastPlayedMatchesMetrics.ranks,
          }
        : undefined,
    },
  };
};

export const parseGoalkeeperPostCardInfoData = (
  data: IGoalkeeperNetZoneCardPlayerStats[],
  playerUuid: string,
): IGoalkeeperNetZoneCardPlayerStats => {
  const playerData = data.find(player => player.player === playerUuid);
  if (!playerData) throw new Error('Player not found');
  return playerData;
};

export const parseGoalkeeperStatsData = (
  data: ITeamWithPlayersWithStatsDTO[] | IPlayerWithStatsDTO[],
  teamUuid?: string,
): IPlayerWithTeamIdAndStats[] => parseGoalkeepersStatsData(data, teamUuid);

export const getGoalkeeperStatsRequestUrl = (
  params: Omit<IGetGoalkeeperStatsParams, 'body'>,
): string => {
  const { competitionsUuids, teamUuid, isPercentile } = params;
  const base = teamUuid
    ? `/goalkeeper/${competitionsUuids}/${teamUuid}`
    : `/goalkeeper/${competitionsUuids}`;

  return buildPath(base, [urlBooleanParamResolver(isPercentile, 'percentile')]);
};
