import { IPlayerCardInfoDTO, IReturnTypeGetPlayerCardInfoAction } from '../../../types';
import { api } from '../api';
import { parsePlayerCardInfoData } from './players.utils';

interface IGetPlayerCardInfoParams {
  competitionUuid: string;
  teamUuid: string;
  playerUuid: string;
  isCalledFromComparePlayers?: boolean;
}

export const playersApi = api.injectEndpoints({
  endpoints: builder => ({
    getPlayerCardInfo: builder.query<IReturnTypeGetPlayerCardInfoAction, IGetPlayerCardInfoParams>({
      query: ({ competitionUuid, teamUuid, playerUuid }) => ({
        url: `/player/${competitionUuid}/${teamUuid}/${playerUuid}`,
        method: 'get',
      }),
      transformResponse: (data: IPlayerCardInfoDTO, _, arg) =>
        parsePlayerCardInfoData(data, arg.competitionUuid, arg.teamUuid),
    }),
  }),
});

export const { useGetPlayerCardInfoQuery, useLazyGetPlayerCardInfoQuery } = playersApi;
