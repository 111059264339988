import { PayloadAction, createSlice } from '@reduxjs/toolkit';

import { IPlayersState, ISimilarPlayerNameRecord } from '../../types';
import { getCompetitionDetail } from '../competitionsDetail';

const initialState: IPlayersState = {
  byId: {},
  allIds: [],
  isLoading: undefined,
  error: undefined,
  similarPlayerNames: {},
};

export const playersSlice = createSlice({
  name: 'players',
  initialState,
  reducers: {
    setSimilarPlayerNames: (state, action: PayloadAction<ISimilarPlayerNameRecord>) => {
      state.similarPlayerNames = action.payload;
    },
    setPlayerEmailById: (state, action: PayloadAction<{ id: string; email: string }>) => {
      state.byId[action.payload.id].email = action.payload.email;
    },
    resetPlayers: () => initialState,
  },
  extraReducers: builder => {
    builder
      .addCase(getCompetitionDetail.pending, state => {
        state.isLoading = true;
        state.error = undefined;
      })
      .addCase(getCompetitionDetail.fulfilled, (state, action) => {
        action.payload.players.forEach(player => {
          if (!state.byId[player.id]) {
            state.byId[player.id] = player;
            state.allIds.push(player.id);
          } else {
            if (!state.byId[player.id].seasonIds.includes(player.seasonIds[0])) {
              state.byId[player.id].seasonIds.push(player.seasonIds[0]);
            }
            if (!state.byId[player.id].teamIds.includes(player.teamIds[0])) {
              state.byId[player.id].teamIds.push(player.teamIds[0]);
            }
            Object.values(player.competitionTeams).forEach(competitionTeam => {
              state.byId[player.id].competitionTeams[competitionTeam.id] = competitionTeam;
            });
          }
        });
        state.isLoading = false;
      })
      .addCase(getCompetitionDetail.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error.message;
      });
  },
});

export const { setSimilarPlayerNames, setPlayerEmailById, resetPlayers } = playersSlice.actions;

export const playersReducer = playersSlice.reducer;
