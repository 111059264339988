import { Dispatch, FC, SetStateAction, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';

import { useAppDispatch, useAppSelector } from '../../../../app/hooks';
import { Button, CardItemList, Loading, PlayerDetails } from '../../../../components';
import { GOALKEEPER_CARDS_METRICS } from '../../../../constants';
import {
  filteredMainFilterDataSelector,
  selectComparePlayers,
  selectGoalkeepers,
  selectNavigation,
  setCompareGoalkeeperItems,
} from '../../../../features';
import { useLocationPaths } from '../../../../hooks';
import { ITranslationKeys } from '../../../../i18n/types';
import { DownloadIcon } from '../../../../icons';
import {
  useLazyGetGoalkeeperCardInfoQuery,
  useLazyGetGoalkeepersStatsQuery,
} from '../../../../services/api';
import {
  IGoalkeeperGoalStatsRequestBody,
  INavigationContentKeys,
  INavigationPageKeys,
  IPlayerWithTeamIdAndStats,
  IReturnTypeGetGoalkeeperCardInfoAction,
  ISelectOption,
} from '../../../../types';
import { createClassNames, transformObjectKeysToKebabCase } from '../../../../utils';
import { useGetGoalkeeperCards } from '../shared';
import './GoalkeeperCardContentComponent.styles.scss';

interface IGoalkeeperCardContentComponentProps {
  submitValues: {
    selectedTeam: ISelectOption | null | undefined;
    selectedGoalkeeper: ISelectOption | null | undefined;
  };
  loadedGoalkeeperData: IReturnTypeGetGoalkeeperCardInfoAction | undefined;
  setLoadedGoalkeeperData: Dispatch<
    SetStateAction<IReturnTypeGetGoalkeeperCardInfoAction | undefined>
  >;
}

const classNames = createClassNames('goalkeeper-card-content-component');

export const GoalkeeperCardContentComponent: FC<IGoalkeeperCardContentComponentProps> = ({
  submitValues,
  loadedGoalkeeperData,
  setLoadedGoalkeeperData,
}) => {
  const { byId } = useAppSelector(selectGoalkeepers);
  const { compareGoalkeeperItems } = useAppSelector(selectComparePlayers);
  const { filteredParts } = useAppSelector(filteredMainFilterDataSelector);
  const { triggerContentReloadValue } = useAppSelector(selectNavigation);
  const dispatch = useAppDispatch();

  const [loadedGoalkeeperStatsData, setLoadedGoalkeeperStatsData] = useState<
    IPlayerWithTeamIdAndStats[]
  >([]);
  const [loadedGoalkeeperStatsRelativeData, setLoadedGoalkeeperStatsRelativeData] = useState<
    IPlayerWithTeamIdAndStats[]
  >([]);

  const [triggerInfo, { error: errorInfo, isFetching: isFetchingInfo }] =
    useLazyGetGoalkeeperCardInfoQuery();
  const [triggerStats, { error: errorStats, isFetching: isFetchingStats }] =
    useLazyGetGoalkeepersStatsQuery();

  const { t } = useTranslation();
  const { navigate, search } = useLocationPaths();
  const { getGoalkeeperCards, getGoalkeeperGoalNetStats } = useGetGoalkeeperCards();

  const onSubmitGoalkeeper = () => {
    if (!submitValues.selectedTeam || !submitValues.selectedGoalkeeper) return;

    triggerInfo(
      {
        competitionUuid: filteredParts[0].id,
        teamUuid: submitValues.selectedTeam.value,
        playerUuid: submitValues.selectedGoalkeeper.value,
      },
      true,
    )
      .unwrap()
      .then(data => setLoadedGoalkeeperData(data))
      .catch(error => {
        console.error('[GoalkeeperCardContentComponent]: Goalkeeper info load error:', error);
      });

    const requestBody: IGoalkeeperGoalStatsRequestBody = {
      gameState: undefined,
      metrics: GOALKEEPER_CARDS_METRICS,
    };

    triggerStats(
      {
        competitionsUuids: [filteredParts[0].id],
        teamUuid: submitValues.selectedTeam.value,
        body: requestBody,
      },
      true,
    )
      .unwrap()
      .then(data => setLoadedGoalkeeperStatsData(data))
      .catch(error => {
        console.error('[GoalkeeperCardContentComponent]: Player info load error:', error);
      });

    triggerStats(
      {
        competitionsUuids: [filteredParts[0].id],
        teamUuid: submitValues.selectedTeam.value,
        body: requestBody,
        isPercentile: true,
      },
      true,
    )
      .unwrap()
      .then(data => setLoadedGoalkeeperStatsRelativeData(data))
      .catch(error => {
        console.error('[GoalkeeperCardContentComponent]: Player info load error:', error);
      });
  };

  useEffect(() => {
    if (filteredParts.length > 1) {
      toast.warn(t(ITranslationKeys.pleaseSelectOnlyOneSeason), {
        toastId: 'pleaseSelectOnlyOneSeason',
      });

      return;
    }

    if (triggerContentReloadValue !== 0) {
      onSubmitGoalkeeper();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [triggerContentReloadValue]);

  if (isFetchingInfo || isFetchingStats) {
    return <Loading />;
  }

  if (errorInfo || errorStats) {
    return <div className='content-info-box'>{t(ITranslationKeys.defaultErrorMessage)}</div>;
  }

  if (!loadedGoalkeeperData) {
    return <div className='content-info-box'>{t(ITranslationKeys.noDataForSelected)}</div>;
  }

  const player = byId[loadedGoalkeeperData.goalkeeperId];

  const handleCompareWithOtherGoalkeeper = () => {
    navigate(INavigationPageKeys.goalkeepers, INavigationContentKeys.compareGoalkeepers, search);
    dispatch(
      setCompareGoalkeeperItems({
        compareGoalkeeperItems: {
          ...compareGoalkeeperItems[0],
          selectedGoalkeeperCompare: {
            value: loadedGoalkeeperData.goalkeeperId,
            label: `${player.name} ${player.surname}`,
            additionalValue: loadedGoalkeeperData.goalkeeperTeamId,
          },
          actualCompareLoadedGoalkeeper: {
            id: loadedGoalkeeperData.goalkeeperId,
            teamId: loadedGoalkeeperData.goalkeeperTeamId,
          },
        },
        itemKey: '0',
      }),
    );
  };

  const goalkeeperStats = loadedGoalkeeperStatsData?.find(
    stats => stats.id === loadedGoalkeeperData.goalkeeperId,
  )?.stats;
  const goalkeeperRelativeStats = loadedGoalkeeperStatsRelativeData?.find(
    stats => stats.id === loadedGoalkeeperData.goalkeeperId,
  )?.stats;

  const goalkeeper = byId[loadedGoalkeeperData.goalkeeperId];

  return (
    <div
      className={classNames({
        ...transformObjectKeysToKebabCase({
          isLoading: isFetchingInfo || isFetchingStats,
        }),
      })}
      data-testid='goalkeepers-page__goalkeeper-card'
    >
      <PlayerDetails
        player={goalkeeper}
        playerInfo={loadedGoalkeeperData.goalkeeperInfo}
        actions={
          <>
            <Button
              label={ITranslationKeys.compareWithOtherGoalkeeper}
              onClick={handleCompareWithOtherGoalkeeper}
            />
            <Button
              label={ITranslationKeys.downloadGoalkeeperCard}
              iconComponent={<DownloadIcon />}
              onClick={() => console.log('TODO: doplnit stažení')}
            />
          </>
        }
      />
      <div className={classNames('statistics')}>
        <CardItemList>
          {getGoalkeeperCards(
            loadedGoalkeeperData.goalkeeperInfo,
            loadedGoalkeeperData.goalkeeperId,
          )}
        </CardItemList>
        <div className={classNames('statistics__goalkeeper-goal-nets')}>
          {goalkeeperStats &&
            goalkeeperRelativeStats &&
            getGoalkeeperGoalNetStats(goalkeeperStats, goalkeeperRelativeStats)}
        </div>
      </div>
    </div>
  );
};
