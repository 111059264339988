import { PayloadAction, createSlice } from '@reduxjs/toolkit';

import { IGoalkeepersState } from '../../types';
import { getCompetitionDetail } from '../competitionsDetail';
import { getGoalkeepersStats } from './goalkeepersActions';

const initialState: IGoalkeepersState = {
  byId: {},
  allIds: [],
  isLoading: undefined,
  error: undefined,
  isStatsLoading: undefined,
  errorStats: undefined,
  isPercentilesLoading: undefined,
  errorPercentiles: undefined,
  isPercentilesActive: undefined,
};

export const goalkeepersSlice = createSlice({
  name: 'goalkeepers',
  initialState,
  reducers: {
    setIsGoalkeepersPercentilesActive: (state, action: PayloadAction<boolean>) => {
      state.isPercentilesActive = action.payload;
    },
    resetGoalkeepers: () => initialState,
  },
  extraReducers: builder => {
    builder
      .addCase(getCompetitionDetail.pending, state => {
        state.isLoading = true;
        state.error = undefined;
      })
      .addCase(getCompetitionDetail.fulfilled, (state, action) => {
        action.payload.goalkeepers.forEach(goalkeeper => {
          if (!state.byId[goalkeeper.id]) {
            state.byId[goalkeeper.id] = goalkeeper;
            state.allIds.push(goalkeeper.id);
          } else {
            if (!state.byId[goalkeeper.id].seasonIds.includes(goalkeeper.seasonIds[0])) {
              state.byId[goalkeeper.id].seasonIds.push(goalkeeper.seasonIds[0]);
            }
            if (!state.byId[goalkeeper.id].teamIds.includes(goalkeeper.teamIds[0])) {
              state.byId[goalkeeper.id].teamIds.push(goalkeeper.teamIds[0]);
            }
            Object.values(goalkeeper.competitionTeams).forEach(competitionTeam => {
              state.byId[goalkeeper.id].competitionTeams[competitionTeam.id] = competitionTeam;
            });
          }
        });
        state.isLoading = false;
      })
      .addCase(getCompetitionDetail.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error.message;
      })
      // Next action case
      .addCase(getGoalkeepersStats.pending, (state, action) => {
        if (action.meta.arg.isPercentile) {
          state.isPercentilesLoading = true;
          state.errorPercentiles = undefined;
        } else {
          state.isStatsLoading = true;
          state.errorStats = undefined;
        }
      })
      .addCase(getGoalkeepersStats.fulfilled, (state, action) => {
        if (action.meta.arg.isPercentile) {
          action.payload.forEach(({ id, teamId, stats }) => {
            const teamStats = state.byId[id].teamStats[teamId];
            state.byId[id].teamStats[teamId] = {
              id: teamId,
              stats: teamStats ? teamStats.stats : {},
              percentiles: stats,
            };
          });
          state.isPercentilesLoading = false;
        } else {
          state.allIds.forEach(playerId => {
            const player = state.byId[playerId];
            player.teamStats = {};
            state.byId[playerId] = player;
          });

          action.payload.forEach(({ id, teamId, stats }) => {
            if (state.byId[id]) {
              state.byId[id].teamStats[teamId] = {
                id: teamId,
                stats,
              };
            } else {
              // Data are unexpectedly missing
              console.warn('[Data are unexpectedly missing]: Goalkeeper not found - id:', id);
            }
          });
          state.isStatsLoading = false;
        }
      })
      .addCase(getGoalkeepersStats.rejected, (state, action) => {
        if (action.meta.arg.isPercentile) {
          state.isPercentilesLoading = false;
          state.errorPercentiles = action.error.message;
        } else {
          state.isStatsLoading = false;
          state.errorStats = action.error.message;
        }
      });
  },
});

export const { setIsGoalkeepersPercentilesActive, resetGoalkeepers } = goalkeepersSlice.actions;

export const goalkeepersReducer = goalkeepersSlice.reducer;
