import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { useAppSelector } from '../../../../app/hooks';
import { filteredMainFilterDataSelector, selectMainFilter } from '../../../../features';
import { ITranslationKeys } from '../../../../i18n/types';
import { IReturnTypeGetGoalkeeperCardInfoAction } from '../../../../types';
import { GoalkeeperCardContentComponent } from './GoalkeeperCardContentComponent';

export const GoalkeeperCardContent = () => {
  const { selectedTeam, selectedGoalkeeper } = useAppSelector(selectMainFilter);
  const { filteredParts } = useAppSelector(filteredMainFilterDataSelector);

  const [loadedGoalkeeperData, setLoadedGoalkeeperData] = useState<
    IReturnTypeGetGoalkeeperCardInfoAction | undefined
  >(undefined);

  const { t } = useTranslation();

  if (filteredParts.length === 0 && !loadedGoalkeeperData) {
    return (
      <div className='content-info-box'>{t(ITranslationKeys.pleaseSelectOneSeasonAndOnePart)}</div>
    );
  }

  if (filteredParts.length > 1 && !loadedGoalkeeperData) {
    return <div className='content-info-box'>{t(ITranslationKeys.pleaseSelectOnlyOneSeason)}</div>;
  }

  if (!(selectedTeam && selectedGoalkeeper) && !loadedGoalkeeperData) {
    return (
      <div className='content-info-box'>{t(ITranslationKeys.pleaseSelectTeamAndGoalkeeper)}</div>
    );
  }

  return (
    <GoalkeeperCardContentComponent
      submitValues={{ selectedTeam, selectedGoalkeeper }}
      {...{ loadedGoalkeeperData, setLoadedGoalkeeperData }}
    />
  );
};
