import { FC, useEffect, useState } from 'react';
import { Control, Controller, UseFormReturn } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';

import { useAppDispatch, useAppSelector } from '../../../../app/hooks';
import {
  BasicPlayerInfo,
  Button,
  Caption,
  CardItemList,
  ICompareGoalkeeperForm,
  Loading,
  SearchInput,
} from '../../../../components';
import { GOALKEEPER_CARDS_METRICS } from '../../../../constants';
import {
  filteredGoalkeepersForCompareOptionsSelector,
  filteredMainFilterDataSelector,
  selectComparePlayers,
  selectGoalkeepers,
  setCompareGoalkeeperItems,
} from '../../../../features';
import { useLocationPaths, usePrepareBaseRequestBody } from '../../../../hooks';
import { ITranslationKeys } from '../../../../i18n/types';
import {
  useLazyGetGoalkeeperCardInfoQuery,
  useLazyGetGoalkeepersStatsQuery,
} from '../../../../services/api';
import {
  IGoalkeeperGoalStatsRequestBody,
  IMainFilterForm,
  IMultiParamsVoid,
  IPlayerWithTeamIdAndStats,
  IReturnTypeGetGoalkeeperCardInfoAction,
  ISelectOption,
} from '../../../../types';
import { createClassNames } from '../../../../utils';
import { useGetGoalkeeperCards } from '../shared';
import './CompareGoalkeepersContentComponent.styles.scss';

interface IComparePlayersContentComponentProps {
  selectedPlayerCompare: ISelectOption | undefined;
  itemIndex: string;
  control: Control<ICompareGoalkeeperForm, any>;
  mainFilterMethods: UseFormReturn<IMainFilterForm>;
}

const classNames = createClassNames('compare-goalkeepers-content-component');

export const CompareGoalkeepersContentComponent: FC<IComparePlayersContentComponentProps> = ({
  selectedPlayerCompare,
  itemIndex,
  control,
  mainFilterMethods,
}) => {
  const { filteredParts } = useAppSelector(filteredMainFilterDataSelector);
  const { compareGoalkeeperItems } = useAppSelector(selectComparePlayers);
  const { byId } = useAppSelector(selectGoalkeepers);
  const filteredGoalkeepersForCompareOptions = useAppSelector(
    filteredGoalkeepersForCompareOptionsSelector,
  );
  const dispatch = useAppDispatch();

  const [loadedGoalkeeperData, setLoadedGoalkeeperData] = useState<
    IReturnTypeGetGoalkeeperCardInfoAction | undefined
  >(undefined);
  const [loadedGoalkeeperStatsData, setLoadedGoalkeeperStatsData] = useState<
    IPlayerWithTeamIdAndStats[]
  >([]);
  const [loadedGoalkeeperStatsRelativeData, setLoadedGoalkeeperStatsRelativeData] = useState<
    IPlayerWithTeamIdAndStats[]
  >([]);

  const [triggerInfo, { error: errorInfo, isFetching: isFetchingInfo }] =
    useLazyGetGoalkeeperCardInfoQuery();
  const [triggerStats, { error: errorStats, isFetching: isFetchingStats }] =
    useLazyGetGoalkeepersStatsQuery();

  const { t } = useTranslation();
  const { activeTab } = useLocationPaths();
  const { getGoalkeeperCards, getGoalkeeperGoalNetStats } = useGetGoalkeeperCards();
  const prepareBaseRequestBody = usePrepareBaseRequestBody();

  const handleChangeGoalkeeper = (
    value: ISelectOption,
    itemKey: string,
    onChange: IMultiParamsVoid,
  ) => {
    onChange(value);
    dispatch(
      setCompareGoalkeeperItems({
        compareGoalkeeperItems: {
          ...compareGoalkeeperItems[itemKey],
          selectedGoalkeeperCompare: value,
        },
        itemKey,
      }),
    );
  };

  const onSubmitGoalkeeper = () => {
    if (filteredParts.length > 1) {
      toast.warn(t(ITranslationKeys.pleaseSelectOnlyOneSeason), {
        toastId: 'pleaseSelectOnlyOneSeason',
      });

      return;
    }

    triggerInfo(
      {
        competitionUuid: filteredParts[0].id,
        teamUuid: selectedPlayerCompare?.additionalValue || '',
        playerUuid: selectedPlayerCompare?.value || '',
      },
      true,
    )
      .unwrap()
      .then(data => setLoadedGoalkeeperData(data))
      .catch(error => {
        console.error('[CompareGoalkeepersContentComponent]: Goalkeeper info load error:', error);
      });

    const { requestBodyBase } = prepareBaseRequestBody(mainFilterMethods.getValues());
    const requestBody: IGoalkeeperGoalStatsRequestBody = {
      ...requestBodyBase,
      metrics: GOALKEEPER_CARDS_METRICS,
    };

    triggerStats(
      {
        competitionsUuids: [filteredParts[0].id],
        teamUuid: selectedPlayerCompare?.additionalValue || '',
        body: requestBody,
      },
      true,
    )
      .unwrap()
      .then(data => setLoadedGoalkeeperStatsData(data))
      .catch(error => {
        console.error('[CompareGoalkeepersContentComponent]: Goalkeeper info load error:', error);
      });

    triggerStats(
      {
        competitionsUuids: [filteredParts[0].id],
        teamUuid: selectedPlayerCompare?.additionalValue || '',
        body: requestBody,
        isPercentile: true,
      },
      true,
    )
      .unwrap()
      .then(data => setLoadedGoalkeeperStatsRelativeData(data))
      .catch(error => {
        console.error('[CompareGoalkeepersContentComponent]: Goalkeeper info load error:', error);
      });
  };

  useEffect(() => {
    if (selectedPlayerCompare) {
      const goalkeeperExist = filteredGoalkeepersForCompareOptions.some(
        option => option.value === selectedPlayerCompare?.value,
      );

      if (!goalkeeperExist) {
        dispatch(
          setCompareGoalkeeperItems({
            compareGoalkeeperItems: {
              ...compareGoalkeeperItems[itemIndex],
              selectedGoalkeeperCompare: undefined,
            },
            itemKey: itemIndex,
          }),
        );
        return;
      }

      onSubmitGoalkeeper();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeTab]);

  const render = () => {
    if (filteredParts.length === 0 && !loadedGoalkeeperData) {
      return (
        <div className='content-info-box'>
          {t(ITranslationKeys.pleaseSelectOneSeasonAndOnePart)}
        </div>
      );
    }

    if (filteredParts.length > 1 && !loadedGoalkeeperData) {
      return (
        <div className='content-info-box'>{t(ITranslationKeys.pleaseSelectOnlyOneSeason)}</div>
      );
    }

    if (isFetchingInfo || isFetchingStats) {
      return <Loading size='medium' disableHorizontalMargin />;
    }

    if (errorInfo || errorStats) {
      return <div className='content-info-box'>{t(ITranslationKeys.defaultErrorMessage)}</div>;
    }

    if (!loadedGoalkeeperData) return null;

    const goalkeeperStats = loadedGoalkeeperStatsData?.find(
      stats => stats.id === loadedGoalkeeperData.goalkeeperId,
    )?.stats;
    const goalkeeperRelativeStats = loadedGoalkeeperStatsRelativeData?.find(
      stats => stats.id === loadedGoalkeeperData.goalkeeperId,
    )?.stats;

    const goalkeeper = byId[loadedGoalkeeperData.goalkeeperId];

    return (
      <>
        <BasicPlayerInfo
          name={`${goalkeeper?.name} ${goalkeeper?.surname}`}
          toi={loadedGoalkeeperData.goalkeeperInfo?.toi ?? 0}
          gp={loadedGoalkeeperData.goalkeeperInfo?.gp ?? 0}
        />
        <CardItemList isAtCompare>
          {getGoalkeeperCards(
            loadedGoalkeeperData.goalkeeperInfo,
            loadedGoalkeeperData.goalkeeperId,
          )}
        </CardItemList>
        <div className={classNames('statistics__goalkeeper-goal-nets')}>
          {goalkeeperStats &&
            goalkeeperRelativeStats &&
            getGoalkeeperGoalNetStats(goalkeeperStats, goalkeeperRelativeStats)}
        </div>
      </>
    );
  };

  return (
    <div className={classNames()} data-testid='goalkeepers-page__compare-goalkeeper'>
      <div
        className={classNames('inputs')}
        data-testid='goalkeepers-page__compare-goalkeeper__inputs'
      >
        <Caption label={ITranslationKeys.selectGoalkeeperToCompare} />
        <Controller
          name={`goalkeepers.${itemIndex}`}
          control={control}
          render={({ field: { value, onChange } }) => (
            <div className={classNames('inputs__control-box')}>
              <SearchInput
                onChange={value => handleChangeGoalkeeper(value, itemIndex, onChange)}
                selected={value ?? undefined}
                options={filteredGoalkeepersForCompareOptions}
                placeholder={`${t(ITranslationKeys.defaultSelectPlaceholder)}`}
                variant='filter'
              />
              <Button
                label={ITranslationKeys.select}
                disabled={!value}
                onClick={onSubmitGoalkeeper}
              />
            </div>
          )}
        />
      </div>
      <div
        className={classNames('cards')}
        data-testid='goalkeepers-page__compare-goalkeepers__card'
      >
        {render()}
      </div>
    </div>
  );
};
